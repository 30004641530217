<template>
    <r-e-dialog :title="dialogTitle" :visible.sync="dialogVisible" show-footer @click-submit="handleSubmitCommunity"
                @click-cancel="handleCancel">
        <el-form ref="formCommunity" label-width="110px" size="small" :model="formCommunity" :rules="rules">
            <el-form-item label="小区名称" prop="name">
                <el-input v-model="formCommunity.name"/>
            </el-form-item>
            <el-form-item label="小区地址" prop="address">
                <el-input v-model="formCommunity.address"/>
            </el-form-item>
            <el-form-item label="备注" prop="common">
                <el-input type="textarea" :rows="3" v-model="formCommunity.common"/>
            </el-form-item>
<!--            <div class="house-manage flex justify-between">
                <el-form-item label="管家姓名" prop="houseManageName">
                    <el-select v-model="formCommunity.houseManageName" placeholder="请选择管家姓名" @change="handleChange"
                               clearable filterable>
                        <el-option v-for="item in users" :key="item.username" :label="item.fullname"
                                   :value="item.username"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="管家手机号" prop="houseManagePhone">
                    <el-input v-model="formCommunity.houseManagePhone" placeholder="管家手机号码"/>
                </el-form-item>
            </div>-->
        </el-form>

        <!--     高德地图     -->
        <GaoDeMap class="gao-de-map" ref="gaoDeMapRef" show-autocomplete @on-autocomplete="handleAutocomplete"
                  :inputChange.sync="formCommunity.name"/>
    </r-e-dialog>
</template>

<script>
import GaoDeMap from "@/components/GaoDeMap";
import {formCommunityRules} from "@/views/rental-housing-management/community-management/data";
import * as communityManagementApi from "@/api/community-management";
import {MessageSuccess, MessageWarning} from "@custom/message";
// import { getUserListApi } from "@/api/publish";

export default {
    name: "layer-add-edit-community",
    data() {
        return {
            dialogTitle: "新增小区",
            dialogVisible: false,
            formCommunity: {
                name: null,
                address: null,
                common: null,
                houseManageName: '',
                houseManagePhone: '',
            },
            users: [],
            rules: formCommunityRules()
        };
    },
    components: {GaoDeMap},
    async created() {
        // 获取管理员
        // let users = await getUserListApi();
        // this.users = users.list;
    },
    methods: {
        layerAddEditCommunityInit(addEdit, row) {
            // 页面初始化，父页面传递数据
            this.addEdit = addEdit;
            this.dialogVisible = true;
            this.dialogTitle = addEdit === "add" ? "新增小区" : "编辑小区";
            if (addEdit === "edit") this.editCommunityInit(row);
        },
        editCommunityInit(row) {
            // 编辑页面回显数据
            this.formCommunity = {...row};
        },
        handleChange(e) {
            // 获取管家手机号
            const {mobile} = this.users.find(v => v.username === e);
            this.formCommunity.houseManagePhone = mobile;
        },
        handleAutocomplete(data) {
            // 通过地图，选择小区，获取数据
            const {name, district, location, address} = data;
            const {lng: longtitude, lat: latitude} = location;
            this.formCommunity = {name, address, district, longtitude, latitude};
        },
        handleSubmitCommunity() {
            // 表单提交
            this.$refs["formCommunity"].validate((valid) => {
                if (valid) {
                    const request = this.addEdit === "add" ? "commonitySave" : "commonityUpdate";
                    console.log(this.formCommunity);
                    const {uuid, name, address, common } = this.formCommunity;
                    const data = { name, address, common };
                    if(uuid) data.uuid = uuid;

                    communityManagementApi[request](data).then(() => {
                        MessageSuccess(`${this.dialogTitle}成功!`);
                        this.handleCancel("update");
                    });
                } else {
                    // MessageWarning("请填写必填值", 1000);
                    return false;
                }
            });
        },
        handleCancel(closeType = "cancel") {
            // 取消、关闭弹窗
            this.dialogVisible = false;
            // this.formCommunity = {};
            this.$refs["formCommunity"].resetFields();
            // 新增、编辑成功，需要刷新数据
            if (closeType === "update")  this.$emit('handleChange');
        }
    }
};
</script>

<style lang="scss" scoped>
.layer-add-edit-community {
    .gao-de-map {
        width: 100%;
        height: VH(400px);
    }

    .house-manage .el-form-item {
        width: 50%;

        .el-select {
            width: 100%;
        }
    }
}
</style>
