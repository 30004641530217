export const tableColumn = [
    {
        prop: "name",
        label: "小区名称",
        showOverflowTooltip: true
    },
    {
        prop: "typeName",
        label: "建筑类型",
    },
    {
        prop: "type",
        label: "层级",
    },
    {
        prop: "maxFloor",
        label: "最大楼层数"
    },
    {
        prop: "address",
        label: "地址"
    },
    {
        prop: "apartmentTotals",
        label: "房源总数"
    },
];

// 新增、编辑小区的表单验证
export function formCommunityRules() {
    return {
        name: [
            { required: true, message: "请输入小区名称", trigger: "blur" },
        ],
        address: [
            { required: true, message: "请输入小区地址", trigger: "blur" },
        ],
        /*houseManageName: [
            { required: true, message: '请选择房管员姓名', trigger: 'blur' },
        ],
        houseManagePhone: [
            { required: true, message: '请输入房管员手机号码', trigger: 'blur' },
        ],*/
    };
}

// 新增、编辑楼幢、单元的表单验证
export function formBuildingRules() {
    return {
        name: [
            { required: true, message: "请输入编号", trigger: "blur" },
        ],
    };
}
