<template>
    <r-e-dialog :title="dialogTitle" :visible.sync="dialogVisible" show-footer @click-submit="handleSubmitBuilding"
                @click-cancel="handleCancel">
        <el-form ref="formBuilding" label-width="110px" size="small" :model="formBuilding" :rules="rules">
            <el-form-item :label="labelName" prop="name">
                <el-input v-model="formBuilding.name"></el-input>
            </el-form-item>
            <el-form-item label="最大楼层数">
                <el-input v-model="formBuilding.maxFloor"></el-input>
            </el-form-item>
        </el-form>

    </r-e-dialog>
</template>

<script>
import {formBuildingRules} from "@/views/rental-housing-management/community-management/data";
import * as communityManagementApi from "@/api/community-management";
import {MessageSuccess, MessageWarning} from "@custom/message";

export default {
    name: "layer-add-edit-building",
    data() {
        return {
            dialogTitle: "新增楼幢",
            dialogVisible: false,
            formBuilding: {
                name: null,
                maxFloor: null,
            },
            buildType: 2,
            rules: formBuildingRules(),
            labelName: "楼幢编号"
        };
    },
    methods: {
        layerAddEditBuildingInit(addEdit, row) {
            // 页面初始化，父页面传递数据
            const {type = 0, uuid} = row;
            const label = type === 0 ? '楼栋' : '单元';
            this.addEdit = addEdit;
            this.buildingType = type;
            this.dialogVisible = true;
            this.labelName = `${label}编号`;
            this.dialogTitle = addEdit === "add" ? `新增${label}` : `编辑${label}`;
            this.formBuilding.uuid = uuid;
            if (addEdit === "edit") this.editBuildingInit(row);
        },
        editBuildingInit({uuid}) {
            const that = this;
            communityManagementApi.buildUnitInfo({uuid}).then(res => {
                const {info: {maxFloor, buildUnitNum}} = res;
                this.formBuilding.maxFloor = maxFloor;
                that.formBuilding.name = buildUnitNum;
            })
        },
        handleSubmitBuilding() {
            // 表单提交
            this.$refs["formBuilding"].validate((valid) => {
                if (valid) {
                    const {name, maxFloor, uuid} = this.formBuilding;
                    const type = this.buildingType;
                    const data = {name, maxFloor, uuid, type};
                    const request = this.addEdit === "add" ? "buildUnitSave" : "buildUnitUpdate";
                    communityManagementApi[request](data).then(() => {
                        MessageSuccess(`${this.dialogTitle}成功!`);
                        this.handleCancel("update");
                    });
                }
            });
        },
        handleCancel(closeType = "cancel") {
            // 取消、关闭弹窗
            this.dialogVisible = false;
            this.$refs["formBuilding"].resetFields();
            // 新增、编辑成功，需要刷新数据
            if (closeType === "update") this.$emit('handleChange');
        }
    }
};
</script>

<style lang="scss" scoped>
.layer-add-edit-building {
    .el-input-number {
        width: 100%;
    }
}
</style>
