<template>
    <section class="community-management">
        <div class="top-bar bg-white">
            <!--    搜索框-->
            <el-input v-model="formSearch.keyword" placeholder="请输入内容" style="margin-right: 10px; width: 250px"
                      size="small"/>
            <el-button type="primary" @click="handleChange" size="small">查询</el-button>
            <el-button size="small" @click="handleResetting">重置</el-button>
            <el-button type="primary" icon="el-icon-download" size="small">导出</el-button>
            <el-button type="primary" icon="el-icon-plus" size="small" @click="openAddCommunity('add', {})">新增小区</el-button>
        </div>
        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getReadyPageList" :height="820" :columns="tableColumn"
                   :query="formSearch">
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" v-if="row.type === '3'" @click="openAddCommunity('edit',row)">修改</span>
                        <span class="table-btn" v-if="row.type === '2' || row.type === '1'" @click="addBuilding('edit',row)">修改</span>
                        <span class="table-btn" v-if="row.type === '3'" @click="addBuilding('add', row,0)">新建楼幢</span>
                        <span class="table-btn" v-if="row.type === '2'" @click="addBuilding('add', row,1)">新建单元</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
        <layer-add-edit-community class="layer-add-edit-community" ref="layerAddEditCommunity" @handleChange="handleChange"/>
        <layer-add-edit-building class="layer-add-edit-building" ref="layerAddEditBuilding" @handleChange="handleChange"/>
    </section>
</template>

<script>
import {tableColumn} from "@/views/rental-housing-management/community-management/data";
import {getCommonityPageApi} from '@/api/community-management';
import {
    layerAddEditCommunity,
    layerAddEditBuilding
} from '@/views/rental-housing-management/community-management/components/index';

export default {
    name: "community-management",
    data() {
        return {
            tableColumn,
            formSearch: {
                keyword: null,
                district: null,
                addressDistrict: null,
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            districtList: [],
            addressDistrictList: [],
        };
    },
    components: {layerAddEditCommunity, layerAddEditBuilding},
    created() {
    },
    mounted() {
    },
    methods: {
        getReadyPageList(params) {
            return getCommonityPageApi(params);
        },

        handleChange() {
            this.$refs["tableRef"].getTableData();
        },

        handleResetting() {
            // this.formSearch = { };
            this.formSearch.keyword = null;
            this.formSearch.district = null;
            this.formSearch.addressDistrict = null;
            this.handleChange();
        },

        openAddCommunity(addEdit, data) {
            this.$refs["layerAddEditCommunity"].layerAddEditCommunityInit(addEdit, data);
        },

        addBuilding(addEdit, data, type) {
            this.$refs["layerAddEditBuilding"].layerAddEditBuildingInit(addEdit, {...data, type});
        }
    },
}
</script>


<style lang="scss" scoped>
.community-management {
    padding: 0 VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(15px);
        padding: VH(15px) VW(15px);
    }

    /deep/ .r-e-table {
        .el-table .cell {
            //white-space: nowrap;
            //text-align: center;
        }
    }
}
</style>